.coming-soon-container {
    background-image: url('../../../assets/images/soon/back.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    height: 100vh;
}

.coming-soon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0 8px 32px 0 rgb(31, 38, 151);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(6px);
}

.coming-soon-container>.text-center {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.coming-soon-container>.text-center>h1 {
    font-size: 2.8rem;
    letter-spacing: 3px;
}

.coming-soon-logo {
    left: 0;
    top: 0;
    z-index: 9999;
    position: relative;
    display: flex;
    justify-content: center !important;
    align-content: center;
}

.coming-soon-img {
    /* width: 15rem; */
    position: relative;
    height: 30%;
    /* width: 30%; */
    top: 23%;
}

.coming-soon-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}